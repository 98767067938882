import React from "react";
import { graphql } from "gatsby";
import * as TermHtml from "../Legal/mobile/termHtml";
import Layout from "../components/layout/index";
import SEO from "../components/seo";

const Terms = () => {
  return (
    <Layout>
      <SEO title="Term and Conditions" />
      <div
        style={{
          padding: "2em 1em",
          // overflow: "scroll",
          fontSize: "1em",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            maxWidth: 960,
          }}
          dangerouslySetInnerHTML={{
            __html: TermHtml["EN"].html,
          }}
        />
      </div>
    </Layout>
  );
};
export default Terms;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
